@import "reset";

// Font family
$body-font-family: "Ubuntu", sans-serif;
$body-font-size: 16px;

// Color variables
$dark-blue: #053749;
$light-blue: #15799d;
$gray: #727171;
$light-gray: #eeeeee;
$pale-yellow: #ffe9d2;
$orange: #efa658;

$screen-xs-min: 576px;
$screen-sm-min: 768px;

body {
  font-family: $body-font-family;
  font-size: $body-font-size;
  padding: 0.5em 0;
  line-height: 1.5em;
}

h1 {
  font-size: $body-font-size * 2.5;
}
h2 {
  font-size: $body-font-size * 1.5;
}
h3 {
  font-size: $body-font-size * 1.3;
}

p {
  padding: 0.5em 0;
  line-height: 1.5em;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: $light-blue;
  &:hover {
    text-decoration: none;
    color: $orange;
  }
}

.container {
  padding: 30px 0;

  @media (max-width: $screen-xs-min) {
    p {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.container.markdown {
  em {
    font-style: italic;
  }

  h1,
  h2,
  h3 {
    padding-top: 0.75em;
    padding-bottom: 0.25em;
  }

  .figure {
    max-width: 960px;
  }

  .figure-caption {
    text-align: center;
    padding-top: 10px;
    font-size: 80%;
  }
  ol {
    list-style: decimal inside none;
  }
}

#splash {
  #splash-svg {
    text {
      transition: opacity 1s;
    }
  }
  #overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .overlay-content {
      background: rgba(255, 255, 255, 0.95);
      padding: 2em;
      transition: opacity 1s;

      h1 {
        padding-bottom: 0.3em;
      }

      nav {
        ul {
          list-style: disc;
          list-style-position: inside;
          li {
            padding-bottom: 0.5em;
          }
        }
      }
    }
  }
}

.detail-header {
  padding-bottom: 2em;
  padding-right: 20px;
  h1 {
    display: none;
    color: $dark-blue;
    padding-bottom: 0.2em;
    &:hover {
      color: $orange;
    }
  }

  h2 {
    color: $gray;
  }

  @media (min-width: $screen-sm-min) {
    h1 {
      display: block;
    }
  }
}

.projects {
  nav {
    li {
      padding: 1em 1em;
      a {
        display: block;
        color: $dark-blue;
        h3 {
          margin: 0.2em 0;
          padding-bottom: 0.1em;
        }
        p {
          color: $gray;
        }
      }
      &:hover {
        background-color: $light-gray;
      }
    }
  }
}

.music {
  h3 {
    margin: 0.5em;
    padding-bottom: 0.1em;
  }
  ul {
    li {
      padding: 1.5em;
      a {
        display: block;
        color: $dark-blue;
        p {
          color: $gray;
        }
      }
      &:hover {
        background-color: $light-gray;
      }
    }
  }
}

.procedural-shapes {
  .vid-padding {
    padding: 3px;
    background: black;
  }
}
